#services-hero {
  height: 100vh;
  background: linear-gradient(
      0deg,
      rgba(30, 30, 30, 0.55),
      rgba(30, 30, 30, 0.55)
    ),
    url(/assets/images/services-hero.jpg) no-repeat right center fixed;
  background-size: cover;
  color: white;
}
#services-hero > .header-text h1 {
  width: 80vw;
}
#services-hero p {
  font-size: clamp(1rem, 4vw, 2rem);
  margin-bottom: 2.25rem;
}
#services-hero h1 {
  margin-bottom: 0.75rem;
}
#services #cta-1 {
  background: linear-gradient(
      0deg,
      rgba(30, 30, 30, 0.47),
      rgba(30, 30, 30, 0.47)
    ),
    url(/assets/images/cta-services.jpg) no-repeat top center fixed;
  background-size: cover;
}
#services #cta-2 {
  background: linear-gradient(
      0deg,
      rgba(30, 30, 30, 0.47),
      rgba(30, 30, 30, 0.47)
    ),
    url(/assets/images/cta-service.jpg) no-repeat top center fixed;
  background-size: cover;
}
#cards-container {
  flex-direction: column;
}
#cards-container .title {
  flex-basis: initial;
  max-width: fit-content;
  width: 100%;
  padding: 0 2rem;
}
#cards-container .square {
  max-width: 30rem;
  height: 9rem;
}
#card-container-1 {
  padding-top: 3rem;
}
#card-container-1,
#card-container-2 {
  margin: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
}
.card {
  position: relative;
  flex-basis: 40%;
  border-radius: 15px;
  padding: 7rem 1.5rem 1.5rem;
  margin: 0.5rem 2rem;
  transform: translateY(15rem);
  opacity: 0;
  transition: all 0.2s linear;
  border: 5px solid $lightBlue;
}
.card-show {
  opacity: 1;
  transform: translateY(0);
}
.card-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: $darkBlue;
}
.card-title h3 {
  font-size: 1.5rem;
  font-weight: lighter;
}
.card-title span {
  font-size: 1.25rem;
  padding-right: 0.5rem;
}
hr {
  text-align: center;
  width: 80%;
  border: 2px solid;
  color: $lightBlue;
}
.card-text p {
  font-size: 1.1rem;
}
.card img {
  position: absolute;
  top: -1rem;
  left: -1rem;
}
#seo-ranking .title h2 {
  font-size: clamp(1.75rem, 7vw, 3.5rem);
}
