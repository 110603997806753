@media only screen and (max-width: 735px) {
  .benefit {
    flex-direction: column;
    padding: 10vh 1rem;
  }
  .title {
    padding: 3rem;
    flex-basis: content;
  }
  .description {
    padding-top: 2rem;
    flex-basis: content;
  }
}
@media only screen and (max-width: 450px) {
  #index-hero {
    background: linear-gradient(
        0deg,
        rgba(30, 30, 30, 0.5),
        rgba(30, 30, 30, 0.5)
      ),
      url(/assets/images/index-hero-mobile.jpg) no-repeat center center;
    background-size: cover;
  }
  #cta-1 {
    background: linear-gradient(
        0deg,
        rgba(30, 30, 30, 0.47),
        rgba(30, 30, 30, 0.47)
      ),
      url(/assets/images/benefit-bottom-mobile.jpg) no-repeat center center;
    background-size: cover;
  }
  #cta-2 {
    background: linear-gradient(
        0deg,
        rgba(30, 30, 30, 0.47),
        rgba(30, 30, 30, 0.47)
      ),
      url(/assets/images/cta-thanks-mobile.jpg) no-repeat center center;
    background-size: cover;
  }
  #contact-hero {
    background: linear-gradient(
        0deg,
        rgba(30, 30, 30, 0.5),
        rgba(30, 30, 30, 0.5)
      ),
      url(/assets/images/contact-hero-mobile.jpg) no-repeat center center;
    background-size: cover;
  }
  #services-hero {
    background: linear-gradient(
        0deg,
        rgba(30, 30, 30, 0.55),
        rgba(30, 30, 30, 0.55)
      ),
      url(/assets/images/service-hero-mobile-new.jpg) no-repeat center center;
    background-size: cover;
  }
  #error-hero {
    background: linear-gradient(
        0deg,
        rgba(30, 30, 30, 0.65),
        rgba(30, 30, 30, 0.65)
      ),
      url(/assets/images/404-hero-mobile.jpg) no-repeat center center fixed;
    background-size: cover;
  }
  #services #cta-1 {
    background: linear-gradient(
        0deg,
        rgba(30, 30, 30, 0.47),
        rgba(30, 30, 30, 0.47)
      ),
      url(/assets/images/cta-service-mobile.jpg) no-repeat center center;
    background-size: cover;
  }
  #services #cta-2 {
    background: linear-gradient(
        0deg,
        rgba(30, 30, 30, 0.47),
        rgba(30, 30, 30, 0.47)
      ),
      url(/assets/images/cta-servics-mobile.jpg) no-repeat center center;
    background-size: cover;
  }
  .square {
    height: 12rem;
  }
  .footer-branding {
    margin-right: 0;
  }
  #logo img {
    max-width: 75%;
  }
  .card {
    flex-basis: 100%;
    margin: 2rem 0 0;
  }
  #card-container-1,
  #card-container-2 {
    margin: 0;
  }
}
