#contact-hero {
  height: 100vh;
  background: linear-gradient(
      0deg,
      rgba(30, 30, 30, 0.5),
      rgba(30, 30, 30, 0.5)
    ),
    url(/assets/images/contact-hero.jpg) no-repeat center center fixed;
  background-size: cover;
  color: white;
}
#contact-hero p {
  font-size: clamp(1.25rem, 4vw, 2rem);
  text-transform: capitalize;
}
#contact-content {
  background-color: white;
  color: #212529;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4rem;
}
#contact-content .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#contact-content section {
  padding: 2rem 0.5rem;
}
.contact-text p {
  line-height: 1.3;
  letter-spacing: 0.04rem;
  padding-top: 0.5rem;
  font-size: clamp(1rem, 2vw, 1.5rem);
}
.contact-text .title {
  font-size: clamp(1.5rem, 2vw, 2rem);
  font-weight: bold;
  font-family: Anton, sans-serif;
  text-transform: uppercase;
  font-weight: lighter;
}
.contact-text {
  flex-basis: 60vw;
}
.contact-form {
  flex-basis: 40vw;
}
form {
  display: flex;
  flex-direction: column;
}
.form-group {
  display: flex;
  flex-direction: column;
}
input,
textarea {
  padding: 0.5rem;
  margin: 0.75rem;
  border: transparent;
  font-size: 0.85rem;
  border-bottom: 1.5px solid $mainBlack;
}
textarea {
  resize: none;
}
.form-group small {
  color: rgb(219, 37, 37);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease;
}
.form-group.error small {
  text-align: center;
  opacity: 1;
  visibility: visible;
}
#checkBox {
  flex-direction: row;
  align-items: center;
  font-size: 0.75rem;
  position: relative;
}
#checkBox small {
  position: absolute;
  margin-top: 2.5rem;
  left: 50%;
  transform: translateX(-50%);
}
#formResponse {
  font-size: 0.85rem;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease;
}
#formResponse.error {
  color: red;
  text-align: center;
  opacity: 1;
  visibility: visible;
}
#formResponse.success {
  text-align: center;
  opacity: 1;
  visibility: visible;
  color: $mainBlack;
}
.form-button {
  margin-top: 1rem;
  color: white;
  padding: 0.25rem 2.5rem;
  font-size: 1rem;
  border-radius: 30px;
  border: 2px solid #ff8e0a;
  transition: background-color 0.15s ease-out, color 0.25s ease-in-out;
  width: fit-content;
  align-self: center;
  background-color: $darkBlue;
}
.form-button:hover {
  background-color: white;
  color: #ff8e0a;
  border: 2px solid $darkBlue;
}
@media only screen and (max-width: 450px) {
  #contact-content {
    padding: 2rem 0.5rem;
  }
  #contact-content {
    flex-direction: column;
  }
  .contact-form {
    width: 90%;
  }
}
