nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transition: background 0.2s linear;
}
.pageNav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2.5rem 0.5rem;
  color: white;
}
.nav-scrolled {
  background: #222222e3;
}
.navbar a {
  padding: 0.5rem 0 0 1vw;
  font-size: clamp(0.75rem, 3.5vw, 1.5rem);
}
.logo p {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  line-height: 95%;
  font-size: 2.25rem;
}
