footer {
  color: white;
  background-color: $mainBlack;
}
footer article {
  padding: 8vh 0.5rem;
  display: flex;
  flex-direction: column;
}
.top-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.top-footer .nav {
  display: flex;
  flex-direction: column;
  align-self: center;
  font-size: 1.2rem;
}
.top-footer .nav a {
  padding: 0.1rem 0 0;
}
.footer-branding {
  text-align: center;
  margin-right: 1rem;
}
.bottom-footer {
  padding: 0.5rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: clamp(0.75rem, 2vw, 1rem);
}
