#error-hero {
  height: 100vh;
  background: linear-gradient(
      0deg,
      rgba(30, 30, 30, 0.65),
      rgba(30, 30, 30, 0.65)
    ),
    url(/assets/images/404-hero.jpg) no-repeat center center fixed;
  background-size: cover;
  color: white;
}
#error-hero h1 {
  text-align: center;
  width: initial;
}
