.square {
  position: absolute;
  max-width: 15rem;
  width: 100%;
  height: 15rem;
  transform: translate(50%, -50%);
  top: 50%;
  right: 50%;
}
.line {
  position: absolute;
  background: #2966dd;
  width: 0;
  height: 0;
  transition: height 0.2s linear, width 0.2s linear;
}
.line-01 {
  transition-delay: 0.3s;
  left: 0;
  top: 0;
  height: 1rem;
}
.line-02 {
  transition-delay: 0.1s;
  right: 0;
  top: 0;
  width: 1rem;
}
.line-03 {
  transition-delay: 0.3s;
  right: 0;
  bottom: 0;
  height: 1rem;
}
.line-04 {
  transition-delay: 0.1s;
  left: 0;
  bottom: 0;
  width: 1rem;
}
.animate-width {
  width: 100%;
}
.animate-height {
  height: 100%;
}
