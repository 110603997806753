*,
*::before,
*::after {
  box-sizing: border-box;
}
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  letter-spacing: 0.07rem;
}
h1,
h2,
h3,
span {
  font-family: "Anton", sans-serif;
  font-weight: lighter;
}
p,
a,
li,
textarea,
label {
  font-family: "Lato", sans-serif;
}
a {
  text-decoration: none;
  color: white;
  transition: color 0.2s linear;
}
a:hover {
  color: $mainOrange;
}
.active {
  color: $mainOrange;
}
.container {
  max-width: 1250px;
  margin: 0 auto;
  width: 100%;
}
.page {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
}
.flex-row-primary {
  display: flex;
  flex-direction: row;
  padding: 25vh 0;
  align-items: center;
}
.button {
  color: white;
  background-color: transparent;
  transition: background-color 0.2s ease-in, color 0.2s ease-in;
}
.button:hover {
  background-color: hsla(0, 0%, 100%, 0.56);
  color: #3f3d56;
}
