#index-hero {
  height: 100vh;
  background: linear-gradient(
      0deg,
      rgba(30, 30, 30, 0.5),
      rgba(30, 30, 30, 0.5)
    ),
    url(/assets/images/index-hero.jpg) no-repeat center center fixed;
  background-size: cover;
  color: white;
}
.header-text {
  padding: 40vh 1rem 0;
}
.header-text h1 {
  font-size: clamp(2rem, 7vw, 4rem);
  margin-bottom: 1.5rem;
  line-height: 120%;
  width: 80vw;
  text-transform: uppercase;
}
.header-text a {
  font-size: clamp(0.75rem, 3.35vw, 1.9rem);
  border-radius: 25px;
  border: 3px solid $mainOrange;
  padding: 0.25rem 2rem;
}
/* main body */
.main h2 {
  font-family: Anton, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: clamp(2rem, 10vw, 4rem);
  line-height: 110%;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: $titleColor;
}
.benefit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: $paddingSection;
}
.benefit p {
  font-size: clamp(1.5rem, 5vw, 2rem);
  line-height: 120%;
  letter-spacing: 0.05em;
  padding: 0.35rem 0;
}
.title {
  flex-basis: 40vw;
  position: relative;
}
.description {
  align-self: flex-end;
  flex-basis: 90vw;
  color: $mainBlack;
}
#benefit-2 {
  font-size: 55px;
}

.cta-link {
  margin-top: 2rem;
  text-align: center;
}
.cta-link a {
  border-radius: 25px;
  font-size: clamp(1rem, 4vw, 1.75rem);
  padding: 0.25rem 2rem;
  border: 3px solid $mainOrange;
}
.cta-text {
  padding: $paddingSection;
}
.cta-text h3 {
  color: white;
  text-align: center;
  font-size: clamp(1.25rem, 8vw, 2.95rem);
  text-transform: capitalize;
  line-height: 115%;
}
#cta-1 {
  background: linear-gradient(
      0deg,
      rgba(30, 30, 30, 0.47),
      rgba(30, 30, 30, 0.47)
    ),
    url(/assets/images/cta-home-1.jpg) no-repeat top center fixed;
  background-size: cover;
}
#cta-2 {
  background: linear-gradient(
      0deg,
      rgba(30, 30, 30, 0.47),
      rgba(30, 30, 30, 0.47)
    ),
    url(/assets/images/cta-home-2.jpg) no-repeat top center fixed;
  background-size: cover;
}
